import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";
import {
  getCategoryProducts,
  searchCategoryProducts,
  getCategoryInformationById,
} from "../../services/Apis";
import ProductThumb from "../product-thumb";
import PreLoader from "../preloader";
import MetaData from "../MetaData";
import NotFound from "../../pages/not-found";

function CategoryProducts() {
  
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [categoryData, setCategoryData] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  
  const location = useLocation();
  const [loadingProducts, setLoadingProducts] = useState(true);
  
  const search = useLocation().search;
  const categoryId = new URLSearchParams(search).get("c");
  const keyword = new URLSearchParams(search).get("keyword");
  
  useEffect(() => {
    if(document.getElementById("root")){
      document.getElementById("root").scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
    }
    
    if (keyword !== undefined && keyword !== null) {
      // console.error("Searching.... ", keyword);
      setLoadingProducts(true);
      searchCategoryProducts(categoryId, keyword).then((result) => {
        setLoadingProducts(false);
        if (
          result &&
          (result.code === 200 || result.code === 400) &&
          result.data
        ) {
          setCategoryProducts(result.data);
        }
      });
    } else {
      setLoadingProducts(true);
      document.getElementById("searchKeyword").value = "";
      getCategoryProducts(categoryId).then((result) => {
        // setLoadingProducts(false);
        if (
          result &&
          (result.code === 200 || result.code === 400) &&
          result.data
        ) {
          setCategoryProducts(result.data);
        }
      });
    }
    
    getCategoryInformationById(categoryId).then((result) => {
      if (
        result &&
        (result.code === 200 || result.code === 400) &&
        result.data &&
        result.data[0]
      ) {
        let urlArr = window.location.href.split('/');
        let currentUrl = urlArr[urlArr.length-1].split('?')[0];

        setLoadingProducts(false);
        setCategoryData(result.data[0]);
        if(result.data[0].seo_url !== currentUrl){
          // console.error('URL dont match');
          setShowNotFound(true);
        } else {
          // console.error('URL MATCHED');
          setShowNotFound(false);
        }
      } else {
        // console.error('ELSE URL NOT MATCHED');
        setLoadingProducts(false);
        setCategoryData(false);
        setShowNotFound(true);
      }
    });
  }, [location]);

  return (
    <>
      {categoryData && !showNotFound &&
        <MetaData data={categoryData} />
      }
      
      {!loadingProducts && categoryData && categoryData.name && categoryData.name!=='' && !showNotFound &&
        <div className="inner-page-banner-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="breadcrumb-area">
                  <h1>{categoryData.name}</h1>
                  {categoryData &&
                    categoryData.short_description &&
                    categoryData.short_description !== "" && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: categoryData.short_description,
                        }}
                      />
                    )}
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li className="ml5 mr5"> / </li>
                    {/* <li>
                      <Link to="/">{categoryTitle}</Link>
                      
                    </li> */}
                    <li>
                      <Link
                        to={
                          "/" +
                          categoryData.seo_url +
                          "?c=" +
                          categoryData.category_id
                        }
                      >
                        {categoryData.name}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      
      {showNotFound &&
        <NotFound />
      }
      {/* {!loadingProducts &&   
        (!categoryData ?
          <NotFound showComponent={true} />
          :
          (!loadingProducts && categoryData && categoryData.seo_url !== urlCategorySeo &&
            <NotFound showComponent={true} />
          )
        ) 
      } */}
      
      {loadingProducts ? (
        <div className="product-area padding-top-0-after-desk">
          <div className="container" id="home-isotope">
            <div className="row featuredContainer text-center">
              <PreLoader />
            </div>
          </div>
        </div>
      ) : categoryProducts.length > 0 && !showNotFound ? (
        <div className="product-area padding-top-0-after-desk">
          <div className="container" id="home-isotope">
            <div className="row featuredContainer">
              <ProductThumb featured="false" data={categoryProducts} openInSeparateWindow={true} />
            </div>
          </div>
        </div>
      ) : (

        !loadingProducts && categoryProducts.length===0 && categoryData &&
          <div className="product-area padding-top-0-after-desk">
            <div className="container" id="home-isotope">
              <div className="woocommerce-info">No products found.</div>
            </div>
          </div>
      )}

      {!loadingProducts && 
        categoryData &&
        !showNotFound &&
        categoryData.description &&
        categoryData.description !== "" && (
          <div className="about-us-page-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div
                    className="about-us-page-right"
                    dangerouslySetInnerHTML={{
                      __html: categoryData.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {categoryData && !showNotFound &&
          <p dangerouslySetInnerHTML={{
            __html: categoryData.meta_keyword,
          }} />
        }
        
    </>
  );
}
export default CategoryProducts;
