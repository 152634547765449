import React, { useEffect, useState } from "react";
// import { getAllPages } from "../../services/Apis";
import { NavLink } from "react-router-dom";

function AllPagesLinks(props) {
  // const [allPages, setAllPages] = useState([])

  // useEffect(() => {

  //     getAllPages().then(result => {
  //         if(result && result.code == 200 && result.data){
  //             // console.error('getAllPages ', result.data);
  //             setAllPages(result.data);
  //             // setLoadingTopAlerts(false);
  //         }
  //     })

  // }, [])

  return (
    <>
      {props.allPages.map((page, index) => {
        return (
          page.slug &&
          page.slug !== "" &&
          (page.status === true ||
            page.status === 1 ||
            page.status === null ||
            page.status === undefined) && (
            <li key={index}>
              <NavLink
                className="nav-link"
                exact={true}
                activeClassName="active"
                to={"/pages/" + page.slug}
              >
                {page.title}
              </NavLink>
            </li>
          )
        );
      })}
    </>
  );
}
export default AllPagesLinks;
