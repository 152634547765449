// import React, { useEffect, useState } from 'react'
import {Link} from "react-router-dom"
import productImg15 from '../../assets/img/product/resource-management.jpeg'
// import productImg16 from '../../assets/img/product/resource-management.jpeg'
function DashboardFeaturedProducts(props) {
    
    return (

        props.data.map( (product, index) => {
            return(
                <div className="col-lg-12 col-md-12 col-sm-4 col-xs-12" style={{'padding':'0'}}>
                    <div className="product-box2">
                        <div className="media">
                            <a className="pull-left" href="#">
                                <img className="img-responsive" src={productImg15} style={{'width':'130px'}} alt="product" />
                            </a>
                            <div className="media-body">
                                <div className="product-box2-content">
                                    <h3>
                                        {/* <Link to={'/'+product.name.replace(' ','-')+'?p='+product.product_id}>{product.name}</Link> */}
                                        <Link target='_blank' to={'/'+(product.seo_url!='' ? product.seo_url : product.name.replace(' ','-')) +'?p='+product.product_id}>{product.name.length>22? product.name.substr(0,18)+' ...' : product.name}</Link>
                                    </h3>
                                    {/* <span>$59.00</span> */}
                                    <p>{product.short_description && product.short_description!=='' ? product.short_description : 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\', making it look like readable English.'}</p>
                                </div>
                                <ul className="product-box2-cart" style={{'margin-top':'20px'}}>
                                    <li>
                                        {/* <Link className="action-buttons-red" to={'/'+product.name.replace(' ','-')+'?p='+product.product_id}>Buy now!</Link> */}
                                        <Link className="action-buttons-red" target='_blank' to={'/'+(product.seo_url!='' ? product.seo_url : product.name.replace(' ','-')) +'?p='+product.product_id}>Buy now!</Link>
                                    </li>
                                    {/* <li><a className="action-buttons-yellow" href="#">View Details</a></li> */}
                                    <li>
                                        {/* <Link className="action-buttons-yellow" to={'/'+product.name.replace(' ','-')+'?p='+product.product_id+'&review'}>Review</Link> */}
                                        <Link className="action-buttons-yellow" target='_blank' to={'/'+(product.seo_url!='' ? product.seo_url : product.name.replace(' ','-')) +'?p='+product.product_id+'&review'}>Review</Link>
                                    </li>
                                    
                                    {/* <li><a href="#"><i className="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-toggle="modal" data-target="#myModal"><i className="fa fa-eye" aria-hidden="true"></i></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        )})

    )
}
export default DashboardFeaturedProducts
