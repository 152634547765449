import React, { useEffect, useState } from 'react'
import { getProductById, generateOrder } from '../../services/Apis'
import {useLocation, useParams, useHistory} from "react-router-dom"

// import noImage from '../../assets/img/product/project.jpeg'
// import noImage from '../../assets/img/product/noimage.jpeg'
import PreLoader from '../../components/preloader'

function Checkout() {

    const location = useLocation()
    const urlParams = useParams()
    const history = useHistory();

    useEffect(() => {
        document.getElementById("root").scrollIntoView({
            behavior: "instant",
            block: "start",
            inline: "nearest",
        });
        getProductDetail();

    }, [location])

    const [productDetail, setProductDetail] = useState([])
    const [formErrorMsg, setFormErrorMsg] = useState('')
    const [productPricing, setProductPricing] = useState('')
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false)

    const getProductDetail = () => {
        getProductById(urlParams.productId).then(result => {
            if(result && result.code == 200 && result.data){
                setProductDetail(result.data);

                if(urlParams.licenseType == 1){
                    if(result.data && result.data[0].sale_price!=null && result.data[0].sale_price!=''){
                        setProductPricing(result.data[0].sale_price)
                    } else {
                        setProductPricing(result.data[0].price)
                    }
                } else if(urlParams.licenseType == 2) {
                    if(result.data && result.data[0].multi_sale_price!=null && result.data[0].multi_sale_price!=''){
                        setProductPricing(result.data[0].multi_sale_price)
                    } else {
                        setProductPricing(result.data[0].multi_price)
                    }
                }

                
            }
        })
    }

    const placeOrder = () => {
        setLoadingPlaceOrder(true)

        if (localStorage.access_token===undefined || localStorage.access_token === '') {
            setLoadingPlaceOrder(false)
            history.push("/login?ref="+window.location.pathname)
            return
        }

        let data = {
            "payment_firstname":" - ",
            "payment_lastname":" - ",
            "payment_company":" - ",
            "payment_address_1":" - ",
            "payment_address_2":" - ",
            "payment_city":" - ",
            "payment_postcode":" - ",
            "payment_country":"162",
            "payment_method":"N/A",
            "comment":" ProductID: "+urlParams.productId,
            "total": productPricing,
            "total_2": productPricing,
            "price": (urlParams.licenseType == 1 ? productDetail[0].price : (urlParams.licenseType == 2 ? productDetail[0].multi_price : 'ERROR')),
            "sale_price": (urlParams.licenseType == 1 ? productDetail[0].sale_price : (urlParams.licenseType == 2 ? productDetail[0].multi_sale_price : 'ERROR')),
            "product": urlParams.productId,
            "quantity": 1,
            "reward": 1,
            "status":"completed",
            "license_type": urlParams.licenseType
        }
        generateOrder( data ).then(
            res => {
                setLoadingPlaceOrder(false)
                console.error('RESPONSE =>', res) //return;
                if( res && res.code == 200 && res.success ){
                    history.push("/my-account/dashboard?order="+res.data.invoice)
                } else {
                    console.error(res.message)
                    setFormErrorMsg(res.message)
                }
            }
        )
    }

    return (
        <>

        <div className="inner-page-banner-area" style={{ "min-height": "165px" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="breadcrumb-area">
                            <h1>Checkout Page</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="checkout-page-area">
            {
            loadingPlaceOrder ?
            <div className="container">
                
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <PreLoader/>
                    </div>
                </div>
            </div>
            :
            <div className="container">
                
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="order-sheet">
                            <h2>Your Order</h2>
                            <ul>
                                {productDetail.map( (prod, index) => {
                                    return( 
                                        <li key={index}>
                                            {index+1}. {prod.name} <span>${productPricing}</span>
                                        </li>
                                    )})
                                }
                                
                            </ul>
                            <h3>Subtotal<span>${productPricing}</span></h3>
                            <h3>Total<span>${productPricing}</span></h3>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="place-order">
                            <p className="invalid-text align-right">{formErrorMsg}</p>
                            <button className="btn-send-message disabled" onClick={() => placeOrder()} type="button" value="Login">Place Order</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        
        </>
    )
}
export default Checkout