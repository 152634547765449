import React from 'react'
import Loader from '../../assets/img/loader5.gif'
function PreLoader(props) {
    return (
        <div>
            {
                props.width ?
                <img style={{'width': props.width}} className="pre-loader" src={Loader} />
                :
                <img className="pre-loader" src={Loader} />

            }
        </div>
    )
}
export default PreLoader