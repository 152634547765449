import React from "react";
import { useLocation } from "react-router-dom";
import NotFound from "../../pages/not-found";
import Product from "../../pages/product/index";
import CategoryProducts from "../category-products/index";

function DecideRouting() {

  const search = useLocation().search;
  // const queryStr = new URLSearchParams(search).get("c");
  if (new URLSearchParams(search).get("c")) {
    return (
      <>
        <CategoryProducts />
      </>
    );
  } else if(new URLSearchParams(search).get("p")){
    return <Product />;
  } else {
    return <NotFound />;
  }
}
export default DecideRouting;
