import React, { useEffect, useState } from 'react'
import { getFeaturedProducts } from '../../services/Apis'
// import { Link } from "react-router-dom"

// import productImg12 from '../../assets/img/product/12.jpg'
// import productImg13 from '../../assets/img/product/13.jpg'
// import productImg15 from '../../assets/img/product/resource-management.jpeg'
import ProductThumb from '../product-thumb'
import PreLoader from '../preloader'

function FeaturedProducts() {
    
    const [featuredProducts, setFeaturedProducts] = useState([])
    const [loadingFeaturedProducts, setLoadingFeaturedProducts] = useState(true)

    useEffect(() => {
        
        getFeaturedProducts(20).then(result => {
            if(result && result.code == 200 && result.data){
                // console.error('getFeaturedProducts ', result.data);
                setFeaturedProducts(result.data)
                setLoadingFeaturedProducts(false)
                // setLoadingTopAlerts(false);
            }
        })
        
    }, [])

    return (
        <>
        {   loadingFeaturedProducts ?
            <div className="product-area padding-top-0-after-desk">
                <div className="container" id="home-isotope">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="isotop-classes-tab myisotop1">
                                <a href="#" data-filter=".on-sale" className="current">Featured Products</a>
                                {/* <a href="#" data-filter=".popular">Popular</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="row featuredContainer text-center">
                        <PreLoader/>
                    </div>
                </div>
            </div>
            :
            featuredProducts.length>0 &&
            <div className="product-area padding-top-0-after-desk">
                <div className="container" id="home-isotope">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="isotop-classes-tab myisotop1">
                                <a href="#" data-filter=".on-sale" className="current">Featured Products</a>
                                {/* <a href="#" data-filter=".popular">Popular</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="row featuredContainer">
                        <ProductThumb featured="true" data={featuredProducts} />
                    </div>
                </div>
            </div>
        }
        </>
    )
}
export default FeaturedProducts