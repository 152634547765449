import React, { useState, useEffect } from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");

export default function YoutubeModal(props) {
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(props.openModal);
  }, [props.openModal]);
  return (
    <Modal
      isOpen={openModal}
      onRequestClose={props.closeModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      className="react-custom-youtube-modal"
      overlayClassName="react-custom-overlay"
      contentLabel=""
    >
      <iframe id="iframeProductVideo" title="Youtube" width="100%" height="100%" allow="autoplay"
      src={'https://www.youtube.com/embed/'+props.videoUrl+'?modestbranding=1&autoplay=1&controls=1&fs=0&loop=1&rel=1&showinfo=0&mute=0'} frameBorder="0"></iframe>
    </Modal>
  );
}
