import React, { useEffect, useState } from 'react'
import { getAllOrders } from '../../services/Apis'
import { Link, NavLink } from "react-router-dom"

function Orders() {
    
    const [allOrders, setAllOrders] = useState([])
    useEffect(() => {
        getAllOrders().then(result => {
            if(result && result.code == 200 && result.data){
                // console.error('getAllOrders ', result.data);
                setAllOrders(result.data);
                // setLoadingTopAlerts(false);
            }
        })
    }, [])

    return (
        <>
            {allOrders && allOrders.length==0 &&
            <div className="woocommerce-info">
                <Link className="woocommerce-Button button" to="/">Go shop</Link>
                No Orders made yet.
            </div>
            }

            {allOrders && allOrders.length>0 &&
            <div className="order-history-page-area" style={{'padding': '0px'}}>
                <div className="container">               
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-6">
                            <div className="order-history-page-top table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <td className="order-history-form-heading">Order Id</td>
                                            <td className="order-history-form-heading">Added Date</td>
                                            <td className="order-history-form-heading">Type</td>
                                            <td className="order-history-form-heading">Amount</td>
                                            <td className="order-history-form-heading">Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allOrders.map( (item, index) => {
                                            return(
                                               <tr>                                        
                                                    <td>
                                                        <Link to={'/order/'+item.order.order_id}>{item.order.invoice_no}<i className="fa fa-paperclip" aria-hidden="true"></i></Link>
                                                    </td>
                                                    <td>{item.order.date_added}</td>
                                                    <td>{item.order.license_type==1?'Single':'Multiple'}</td>
                                                    <td>${item.order.total}</td>
                                                    <td className="complete">{item.order.status}</td>
                                                </tr>
                                            )})
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
export default Orders