import React, { useEffect, useState } from "react";

import { getAllCategories, getAllPages } from "../src/services/Apis";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./assets/css/normalize.css";
import "./assets/css/main.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/font/flaticon.css";
import "./assets/css/font/flaticon.css";
import "./assets/css/font/flaticon.css";

// import './assets/css/owl.carousel.min.css'
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/meanmenu.min.css";
import "./assets/css/lib/custom-slider/css/nivo-slider.css";
import "./assets/css/lib/custom-slider/css/preview.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";

import Home from "../src/pages/home/index";
import MyAccount from "../src/pages/my-account/index";
// import Category from '../src/pages/category/index'
// import ContactUs from '../src/pages/contact-us/index'
import Login from "../src/pages/login/index";
import Signup from "../src/pages/signup/index";
import Forgot from "../src/pages/forgot/index";
import Header from "../src/layout/header/index";
import Footer from "../src/layout/footer/index";
// import NotFound from "../src/pages/not-found/index";
import ContentPages from "../src/pages/content-pages/index";
import Checkout from "../src/pages/checkout/index";
import OrderDetail from "../src/components/orders/detail";
import DecideRouting from "../src/components/decide-routing/index";
import SetPassword from "./pages/set-password";

function App() {
  const [allCategories, setAllCategories] = useState([]);
  const [allPages, setAllPages] = useState([]);

  useEffect(() => {
    // console.error("APP LOADED ..... ");
    localStorage.signupFromAddToCart = false;
    localStorage.signupFromProduct = "";

    getAllCategories().then((result) => {
      if (result && result.code == 200 && result.data) {
        setAllCategories(result.data);
      }
    });
    getAllPages().then((result) => {
      if (result && result.code == 200 && result.data) {
        // console.error('getAllPages ', result.data);
        setAllPages(result.data);
        // setLoadingTopAlerts(false);
      }
    });
  }, []);

  return (
    <>
      <div className="wrapper-area">
        <Router>
          <Header allCategories={allCategories} allPages={allPages} />
          <Switch>
            {/* <Route exact path="/not-found">
              <NotFound />
            </Route> */}
            <Route exact path="/checkout/:productId/:licenseType">
              <Checkout />
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            {/* <Route exact path="/error">
              <NotFound />
            </Route> */}
            <Route exact path="/set-password">
              <SetPassword />
            </Route>
            <Route exact path="/signup">
              <Signup />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/:seo_url">
              <DecideRouting />
            </Route>
            <Route exact path="/pages/:slug">
              <ContentPages />
            </Route>
            <Route exact path="/my-account/:id">
              <MyAccount />
            </Route>
            <Route exact path="/order/:id">
              <OrderDetail />
            </Route>
            <Route exact path="/">
              <Home allCategories={allCategories} allPages={allPages} />
            </Route>
          </Switch>
          <Footer allCategories={allCategories} allPages={allPages} />
        </Router>
      </div>
    </>
  );
}

export default App;
