import React from "react";
import DOMPurify from "dompurify";

function ContentArea(props) {
  let dirtyHTML = DOMPurify.sanitize(props.description);
  return (
    <>
      <div className="about-us-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div
                className="about-us-page-right"
                dangerouslySetInnerHTML={{
                  __html: dirtyHTML,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContentArea;
