import React, { useEffect, useState } from 'react'
import { getSaleProducts } from '../../services/Apis'
import ProductThumb from '../product-thumb'
import PreLoader from '../preloader'

function SaleProducts() {

    const [saleProducts, setSaleProducts] = useState([])
    const [loadingSaleProducts, setLoadingSaleProducts] = useState(true)

    useEffect(() => {
        
        getSaleProducts(20).then(result => {
            // setSaleProducts(result);
            if(result && result.code == 200 && result.data){
                // console.error('getSaleProducts ', result.data);
                setSaleProducts(result.data)
                setLoadingSaleProducts(false)
                // setLoadingTopAlerts(false);
            }
        })
        
    }, [])
    
    return (
        <>
            { loadingSaleProducts ?
            <div className="product-area padding-top-0-after-desk">
                <div className="container" id="home-isotope">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="isotop-classes-tab myisotop1">
                                <a href="#" data-filter=".on-sale" className="current">On Sale</a>
                            </div>
                        </div>
                    </div>
                    <div className="row featuredContainer text-center">
                        <PreLoader/>
                    </div>
                </div>
            </div>
            :
            saleProducts && saleProducts.length>0 &&
            <div className="product-area padding-top-0-after-desk">
                <div className="container" id="home-isotope">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="isotop-classes-tab myisotop1">
                                <a href="#" data-filter=".on-sale" className="current">On Sale</a>
                            </div>
                        </div>
                    </div>
                    <div className="row featuredContainer">
                        <ProductThumb featured="false" data={saleProducts} />
                    </div>
                </div>
            </div>
        }
        </>
    )
}
export default SaleProducts