import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import {Link} from "react-router-dom";
import { processLogin } from "../../services/Apis";
import PreLoader from "../../components/preloader";

function Login() {
  const history = useHistory();
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [emailError, setEmailError] = useState("");
  const [PwdError, setPwdError] = useState("");
  const [userStatusError, setUserStatusError] = useState("");
  const [loadLogin, setLoadLogin] = useState(false);

  const search = useLocation().search;
  const referrer = new URLSearchParams(search).get("ref");
  const checkouter = new URLSearchParams(search).get("checkout");
  

  useEffect(() => {
    checkUserWasExpired();
  }, []);

  const takeToForgotPassword = () => {
    window.location = '/forgot'
  };

  const checkUserWasExpired = () => {
    if (
      localStorage.user_expired &&
      (localStorage.user_expired == 1 || localStorage.user_expired == 2)
    ) {
      // setUserStatusError(localStorage.user_expired==1 ? 'Your session is expired, Please login again.' : 'Your User is blocked.');
      setUserStatusError(
        localStorage.user_expired == 1 ? "" : "Your User is blocked."
      );
      localStorage.clear();
    } else if (localStorage.access_token && localStorage.access_token != "") {
      setUserStatusError("");
      history.push("/");
    }
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    const elem = event.target.elements;
    if (elem.email.value === "" && elem.password.value === "") {
      setFormErrorMsg("Please provide Email and Password.");
      setEmailError("error");
      setPwdError("error");
      return;
    }
    if (elem.email.value === "") {
      setFormErrorMsg("Please provide Email.");
      setEmailError("error");
    } else {
      setEmailError("");
    }
    if (elem.password.value === "") {
      setFormErrorMsg("Please provide Password.");
      setPwdError("error");
    } else {
      setPwdError("");
    }

    if (elem.email.value != "" && elem.password.value != "") {
      setLoadLogin(true);
      setEmailError("");
      setPwdError("");
      setFormErrorMsg("");
      processLogin(elem.email.value, elem.password.value).then((res) => {
        // console.error('RESPONSE =>', res); return;
        setLoadLogin(false);
        if (
          res &&
          res.code == 200 &&
          res.data &&
          res.data.token &&
          res.data.token != ""
        ) {
          localStorage.setItem("access_token", res.data.token);
          localStorage.setItem("user_data", JSON.stringify(res.data));
          console.error("referrer ", referrer);
          if (referrer && referrer != "") {
            window.location = referrer.substr(1, referrer.length);
          } else if (checkouter && checkouter != "") {
            window.location = '/checkout/'+checkouter.split('_')[0]+'/'+checkouter.split('_')[1];
          } else {
            window.location = "/";
          }
        } else {
          console.error(res.message);
          setFormErrorMsg(res.message);
        }
      });
    }
  };

  return (
    <>
      <div className="login-registration-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="login-registration-field">
                <h2 className="cart-area-title">Login</h2>

                {userStatusError && (
                  <p className="invalid-text">{userStatusError}</p>
                )}
                <form onSubmit={(e) => handleFormSubmission(e)}>
                  <label>Email address *</label>
                  <input
                    type="text"
                    id="inputEmail"
                    name="email"
                    className={
                      emailError ? emailError + " form-control" : "form-control"
                    }
                    required=""
                    autoFocus={true}
                    placeholder="E-mail"
                  />
                  <label>Password *</label>
                  <input
                    type="password"
                    name="password"
                    id="inputPassword"
                    className={PwdError ? PwdError : ""}
                    placeholder="Password"
                    required=""
                  />
                  <p className="invalid-text">{formErrorMsg}</p>

                  {loadLogin ? (
                    <div className="text-center">
                      <PreLoader width="20%" />
                    </div>
                  ) : (
                    <>
                      <button
                        className="btn-send-message"
                        type="submit"
                        value="Login"
                      >
                        LOGIN
                      </button>
                      <button style={{'float': 'right'}} className="btn-already-user" type="button" onClick={() => takeToForgotPassword()} value="Forgot Password">Forgot Password</button>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
