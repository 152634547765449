import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { resetPasswordRequest } from "../../services/Apis";
import PreLoader from "../../components/preloader";

function Forgot() {
  const history = useHistory();
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [formIsDone, setFormIsDone] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
//   useEffect(() => {
//     checkUserWasExpired();
//   }, []);

  const takeToLogin = () => {
    window.location = '/login'
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    const elem = event.target.elements;
    if (elem.email.value === "") {
      setFormErrorMsg("Please provide Email.");
      setEmailError("error");
    } else {
      setEmailError("");
    }
    
    if (elem.email.value !== "") {
      setForgotPassword(true);
      setEmailError("");
      setFormErrorMsg("");
      resetPasswordRequest(elem.email.value).then((res) => {
        // console.error('RESPONSE =>', res); return;
        setForgotPassword(false);
        if (
          res &&
          res.code === 200 &&
          res.data
        ) {
            elem.email.value = '';
            setFormIsDone(true);
        } else {
          console.error(res.message);
          setFormErrorMsg(res.message);
        }
      });
    }
  };

  return (
    <>
      <div className="login-registration-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="login-registration-field">
                <h2 className="cart-area-title">Forgot Password</h2>
                {formIsDone &&
                    <label>Your request has been submitted. Please check your email.</label>
                }
                {!formIsDone &&
                <form onSubmit={(e) => handleFormSubmission(e)}>
                  <label>Email address *</label>
                  <input
                    type="text"
                    id="inputEmail"
                    name="email"
                    className={
                      emailError ? emailError + " form-control" : "form-control"
                    }
                    required=""
                    autoFocus={true}
                    placeholder="E-mail"
                  />
                  <p className="invalid-text">{formErrorMsg}</p>

                  {forgotPassword ? (
                    <div className="text-center">
                      <PreLoader width="20%" />
                    </div>
                  ) : (
                    <>
                      <button
                        className="btn-send-message"
                        type="submit"
                        value="Submit"
                      >
                        Submit
                      </button>
                      <button style={{'float': 'right'}} className="btn-already-user" type="button" onClick={() => takeToLogin()} value="Take to Login">Take to Login</button>
                    </>
                  )}
                </form>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Forgot;
