import React from 'react'

const ProgressBar = (props) => {
  const { completed } = props;
  const containerStyles = {
    height: 22,
    width: '74%',
    backgroundColor: "rgb(233, 233, 233)",
    borderRadius: '.5rem',
    marginBottom: 40
  }

  const fillerStyles = {
    height: '100%',
    // transition: 'width 1s ease-in-out',
    transition: 'width 1s ease',
    // transition: '1s ease',
    // transitionDelay: '0.5s',
    width: `${completed}%`,
    backgroundColor: 'rgb(62, 122, 235)',
    borderRadius: '1rem',
    textAlign: 'center'
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8rem'
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;