// import React from 'react'
import Helmet from "react-helmet";
function MetaData(props) {
    return (
        <Helmet>
        <title>{props.data.meta_title}</title>
        <meta name="title" content={props.data.meta_title} />
        <meta name="description" content={props.data.meta_description} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={props.data.meta_title} />
        <meta property="og:description" content={props.data.meta_description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={props.data.meta_title} />
        <meta name="twitter:card" content="summary_large_image" />
        
        {props.data && props.data.date_added && props.data.date_added!=='' &&
        <meta property="article:published_time" content={props.data.date_added} />
        }
        {(props.data && props.data.date_modified && props.data.date_modified!=='') && (props.data.date_added !== props.data.date_modified) &&
        <meta property="article:modified_time" content={props.data.date_modified} />
        }

        {/* {props.data.meta_keyword} */}
        
      </Helmet>
    )
}
export default MetaData