import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.css';
// import Logo from '../../assets/images/login-logo.png'
import { resetPassword } from "../../services/Apis";
import PreLoader from "../../components/preloader";

function SetPassword() {

    const history = useHistory();
    const [formIsDone, setFormIsDone] = useState(false);
    const [resetPwdErrorMsg, setResetPwdErrorMsg] = useState('')
    const [emailError, setEmailError] = useState("");
    const [forgotPassword, setForgotPassword] = useState(false);

    const takeToLogin = () => {
        window.location = '/login'
    };
    
    const handleFormSubmission = event => {
        event.preventDefault()
        const elem = event.target.elements;
        const hashStr = window.location.href.split('?');
        if(hashStr && hashStr.length && hashStr[hashStr.length-1] && hashStr[hashStr.length-1]!==''){
            if (elem.password.value === '' || elem.confirm_password.value === '') {
                setResetPwdErrorMsg("Please provide Password and Confirm Password.");
            } else if (elem.password.value !== elem.confirm_password.value) {
                setResetPwdErrorMsg("Password and Confirm Password doesn't match.");
            } else {
                setForgotPassword(true);
                resetPassword( hashStr[hashStr.length-1], elem.password.value, elem.confirm_password.value ).then(
                    res => {
                        setForgotPassword(false);
                        console.error('RESPONSE =>', res);
                        if(res && res.code === 200 && res.data){
                            // history.push("/password-changed");
                            elem.password.value = '';
                            elem.confirm_password.value = '';
                            setFormIsDone(true);
                        } else {
                            setResetPwdErrorMsg(res.message);
                        }
                    }
                )
            }
        } else {
            setResetPwdErrorMsg('Your request is expired - Please contact Website administrator.');
        }
        
        
      }

    // return (
    //     <main className="text-center form-signin">
    //         <form className="form-set-body" onSubmit={(e)=> validateNewPwd(e)}>
    //             <img className="login-branc" src={Logo} alt="" width="171" height="171" />

    //             <h1 className="h3 mb-3">Reset password</h1>
    //             {/* <p>Your new password must not be the same to your current password.</p> */}

    //             {/* <label htmlFor="password" className="visually-hidden">Password</label> */}
    //             <input type="password" id="password" name="password" className="form-control mt43" placeholder="Password" required="" autoFocus={true} />

    //             {/* <label htmlFor="confirm_password" className="visually-hidden">Confirm Password</label> */}
    //             <input type="password" id="confirm_password" name="confirm_password" className="form-control" placeholder="Confirm Password" required="" autoFocus={true} />

    //             <p className="Invalid-text">{resetPwdErrorMsg}</p> 
    //             <button className="w-100 btn btn-lg login-btn" type="submit">Update</button>
    //         </form>
    //     </main>
    // )
    return (
        <>
          <div className="login-registration-page-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="login-registration-field">
                    <h2 className="cart-area-title">Reset Password</h2>
                    {formIsDone &&
                        <label>Your password has been changed.</label>
                    }
                    {!formIsDone &&
                    <form onSubmit={(e) => handleFormSubmission(e)}>
                      <label>New Password *</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className={
                          emailError ? emailError + " form-control" : "form-control"
                        }
                        required=""
                        autoFocus={true}
                        placeholder="Password"
                      />
                      <label>Confirm Password *</label>
                      <input
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        className={
                          emailError ? emailError + " form-control" : "form-control"
                        }
                        required=""
                        autoFocus={true}
                        placeholder="Confirm Password"
                      />
                      <p className="invalid-text">{resetPwdErrorMsg}</p>
    
                      {forgotPassword ? (
                        <div className="text-center">
                          <PreLoader width="20%" />
                        </div>
                      ) : (
                        <>
                          <button
                            className="btn-send-message"
                            type="submit"
                            value="Submit"
                          >
                            Submit
                          </button>
                          <button style={{'float': 'right'}} className="btn-already-user" type="button" onClick={() => takeToLogin()} value="Take to Login">Login</button>
                        </>
                      )}
                    </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default SetPassword