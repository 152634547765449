import React, { useEffect, useState } from "react";
import { getOrderById } from "../../services/Apis";
import { useLocation, useParams, useHistory } from "react-router-dom";

function OrderDetail() {
  const location = useLocation();
  const urlParams = useParams();
  const history = useHistory();

  useEffect(() => {
    if (
      localStorage.access_token === undefined ||
      localStorage.access_token === ""
    ) {
      history.push("/login");
    } else {
      getOrderDetail();
    }
  }, [location]);

  const [orderDetail, setOrderDetail] = useState({ order: {}, product: {} });
  // const [formErrorMsg, setFormErrorMsg] = useState('')

  const getOrderDetail = () => {
    getOrderById(urlParams.id).then((result) => {
      if (result && result.code == 200 && result.data && result.data[0]) {
        setOrderDetail(result.data[0]);
        // console.error('RESULT => ', orderDetail);
      }
    });
  };

  return (
    <>
      <div className="order-details-page-area">
        <div className="container">
          {/* <h2 className="order-details-page-title">Thank You. Your Order Has been Received.</h2>  */}
          <ul className="order-details-summery">
            <li>
              Order Number:<span>{orderDetail.order.invoice_no}</span>
            </li>
            <li>
              Order Date:<span>{orderDetail.order.date_added}</span>
            </li>
            <li>
              Email:
              <span style={{ textTransform: "lowercase" }}>
                {orderDetail.order.customer && orderDetail.order.customer.email}
              </span>
            </li>
            <li>
              Total:<span>${orderDetail.order.total}</span>
            </li>
            <li>
              Document:
              {!orderDetail.product.document_url ||
              orderDetail.product.document_url === "" ? (
                <span>N/A</span>
              ) : (
                <span>
                  <a
                    href={
                      "https://www.ezowo.net/apis/v1/getdocument?invoice=" +
                      orderDetail.order.invoice_no
                    }
                    target="_blank"
                  >
                    Download
                  </a>
                </span>
              )}
            </li>
          </ul>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3>Order Details</h3>
              <div className="order-details-page-top table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <td className="order-details-form-heading">Product</td>
                      <td className="order-details-form-heading">Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {orderDetail.product && orderDetail.product.name
                          ? orderDetail.product.name
                          : ""}
                      </td>
                      <td>${orderDetail.order.total}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong> Subtotals</strong>
                      </td>
                      <td>
                        <strong> ${orderDetail.order.total}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Payment Method</strong>
                      </td>
                      <td className="capitalize">
                        <strong>{orderDetail.order.payment_method}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>${orderDetail.order.total}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3>Billing Address</h3>
                            <div className="order-details-page-bottom">
                            <ul>
                                    <li><strong>Name:</strong> Mr. Jack Kalis</li>
                                    <li><strong>Address:</strong> 59 Street, Melbourne Kazi City # 34 Road, House #10.</li>
                                    <li><strong>Country:</strong> Australia</li>
                                    <li><strong>Mail:</strong> info@metro.com</li>
                                    <li><strong>Phone:</strong> + 123 45678910</li>                         
                            </ul>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
export default OrderDetail;
