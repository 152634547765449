import React from 'react'
// import { useHistory } from 'react-router-dom'

function AdvantageArea() {
    
    return (
        <>
            <div className="advantage1-area mt30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="media">
                                    <a className="pull-left" href="#">
                                        <i className="flaticon-truck"></i>
                                    </a>
                                    <div className="media-body">
                                        <h3>FREE SHIPPING</h3>
                                        <p>On All Orders</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="media">
                                    <a className="pull-left" href="#">
                                        <i className="flaticon-headphones"></i>
                                    </a>
                                    <div className="media-body">
                                        <h3>24/7 SERVICE</h3>
                                        <p>Get Help When You Need</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="media">
                                    <a className="pull-left" href="#">
                                        <i className="flaticon-reload"></i>
                                    </a>
                                    <div className="media-body">
                                        <h3>100% MONEY BACK</h3>
                                        <p>Within 30 Day Guarantee</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="advantage3-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="advantage-area-box-icon">
                                    <a href="#"><i className="flaticon-truck"></i></a>
                                </div>
                                <div className="advantage-area-box-content">
                                    <h3>FREE SHIPPING WORLDWIDE</h3>
                                    <p>On All Orders Of USD $50</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="advantage-area-box-icon">
                                    <a href="#"><i className="flaticon-headphones"></i></a>
                                </div>
                                <div className="advantage-area-box-content">
                                    <h3>24/7 CUSTOMER SERVICE</h3>
                                    <p>Get Help When You Need It</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="advantage-area-box">
                                <div className="advantage-area-box-icon">
                                    <a href="#"><i className="flaticon-reload"></i></a>
                                </div>
                                <div className="advantage-area-box-content">
                                    <h3>100% MONEY BACK</h3>
                                    <p>30 Day Money Back Guarantee.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default AdvantageArea