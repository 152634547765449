import React from 'react'
import {Link} from "react-router-dom"

function NotFound() {
    return (
        <div className="page-error-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="page-error-top">
                            <span>404</span>
                            <p>Sorry Page Was Not Found</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="page-error-bottom">
                            <p>The page you are looking is not available or has been removed.</p>
                            <p>Try going to Home Page by using the button below.</p>
                            <Link className="btn btn-error" to="/">Go To Home Page</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotFound