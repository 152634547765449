import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPageBySlug } from "../../services/Apis";
import MetaData from "../../components/MetaData";
import NotFound from "../not-found";
import PreLoader from "../../components/preloader";

function ContentPages() {
  const [pageDetail, setPageDetail] = useState(false);
  const location = useLocation();
  const urlParams = useParams();
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    getPageDetail();
  }, [location]);

  const getPageDetail = () => {
    let slug = urlParams.slug;
    setLoadingProducts(true);
    getPageBySlug(slug).then((result) => {
      setLoadingProducts(false);
      if (result && result.code === 200 && result.data && result.data[0]) {
        setPageDetail(result.data[0]);
      }
    });
  };

  return (
    <>
      {pageDetail && (
        <MetaData data={pageDetail} />
      )}

      {pageDetail && pageDetail.title && pageDetail.title!=='' &&

        <>
          <div className="inner-page-banner-area content-header-height">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="breadcrumb-area">
                    <h1>{pageDetail.title}</h1>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li className="ml5 mr5"> / </li>
                      <li>{pageDetail.title}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loadingProducts &&
            <div className="product-area padding-top-0-after-desk">
              <div className="container" id="home-isotope">
                <div className="row featuredContainer text-center">
                  <PreLoader />
                </div>
              </div>
            </div>
          }

          {!loadingProducts &&
          <div className="about-us-page-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div
                    className="about-us-page-right"
                    dangerouslySetInnerHTML={{ __html: pageDetail.description }}
                  />
                </div>
              </div>
            </div>
          </div>}
        </>
      }

      {!loadingProducts && !pageDetail && 
        <NotFound />
      }

      {pageDetail &&
        <p dangerouslySetInnerHTML={{
          __html: pageDetail.meta_keyword
        }} />
      }

    </>
  );
}
export default ContentPages;
