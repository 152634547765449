import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
function Signup() {
  const history = useHistory();
  useEffect(() => {
    checkUsedrLoggedIn();
  }, []);

  const checkUsedrLoggedIn = () => {
    if (localStorage.access_token && localStorage.access_token != "") {
      history.push("/");
    }
  };

  return <></>;
}
export default Signup;
