import Services from "./service";

const getFeaturedProducts = async (limit) => {
  try {
    // let response = await Services.getData('getfeaturedproducts', { 'limit' : limit })
    let response = await Services.getData("getfeaturedproducts");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getSaleProducts = async (limit) => {
  try {
    // let response = await Services.getData('getsaleproducts', { 'limit' : limit })
    let response = await Services.getData("getsaleproducts");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getRelatedProducts = async (p_id) => {
  try {
    let response = await Services.getData(
      "getprimarycategoryproductsbyproductid",
      { p_id: p_id }
    );
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getAllCategories = async () => {
  try {
    let response = await Services.getData("getallcategories");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getAllPages = async () => {
  try {
    let response = await Services.getData("getallinformationpages");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getCategoryProducts = async (categoryId) => {
  const queryData = { c_id: categoryId };
  try {
    let response = await Services.getData("getproductsbycategory", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const searchCategoryProducts = async (categoryId, keyword) => {
  const queryData = { c_id: categoryId, keyword: keyword };
  try {
    let response = await Services.getData("searchproduct", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getProductById = async (productId) => {
  const queryData = { p_id: productId };
  try {
    let response = await Services.getData("getproduct", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getOrderById = async (orderId) => {
  const queryData = { o_id: orderId };
  try {
    let response = await Services.getDataWithToken("getorderbyid", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getCategoryInformationById = async (id) => {
  const queryData = { c_id: id };
  try {
    let response = await Services.getData("getcategorybyid", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getPageBySlug = async (slug) => {
  const queryData = { slug: slug };
  try {
    let response = await Services.getData("getpagecontentbyslug", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const updateProfile = async (data) => {
  try {
    let response = await Services.postData("userupdate", data);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const saveProductReview = async (data) => {
  try {
    let response = await Services.postData("savereview", data);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const processLogin = async (email, password) => {
  const queryData = { email: email, password: password };
  try {
    let response = await Services.postDataWithoutToken("login", queryData);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const checkEmailExists = async (email) => {
  try {
    let response = await Services.getData("checkuseremail", { email: email });
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const processSignupApi = async (signupObject) => {
  try {
    let response = await Services.postDataWithoutToken(
      "registration",
      signupObject
    );
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const getAllOrders = async () => {
  try {
    let response = await Services.getDataWithToken("getallorders");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const generateDownloadLinkByInvoice = async (data) => {
  try {
    let response = await Services.getDataWithToken(
      "generatedownloadlinkbyinvoice",
      data
    );
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const generateOrder = async (orderObject) => {
  try {
    let response = await Services.postData("saveorder", orderObject);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const logout = async () => {
  try {
    let response = await Services.getDataWithToken("logout");
    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

const resetPasswordRequest = async email => {
  const queryData = { 'email': email }
  try {
    let response = await Services.postDataWithoutToken('forgotpassword', queryData)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

const resetPassword = async (hash, password, confirm_password) => {
  const queryData = {
    hash,
    password,
    confirm_password
  }
  try {
    let response = await Services.postDataWithoutToken('resetpassword?id='+hash, queryData)
    return await response.json()
  } catch (error) {
    console.log(error)
  }
}

export {
  getFeaturedProducts,
  getRelatedProducts,
  getSaleProducts,
  getAllCategories,
  getAllPages,
  getCategoryProducts,
  searchCategoryProducts,
  getPageBySlug,
  getProductById,
  processLogin,
  processSignupApi,
  updateProfile,
  getAllOrders,
  generateOrder,
  getOrderById,
  logout,
  generateDownloadLinkByInvoice,
  saveProductReview,
  getCategoryInformationById,
  checkEmailExists,
  resetPasswordRequest,
  resetPassword
};
